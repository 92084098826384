import { default as _has } from 'lodash/has'
import { default as _get } from 'lodash/get'

/**
 * Used to match notion database property ids
 * @param propertyId1
 * @param propertyId2
 */
export function notionPropertiesMatch (propertyId1, propertyId2) {
  return decodeURI(propertyId1) === decodeURI(propertyId2) ||
    propertyId1 === propertyId2 ||
    decodeURI(propertyId1) === propertyId2 ||
    propertyId1 === decodeURI(propertyId2)
}

export function notionGetProperty (val, propertyId) {
  if (!val || typeof val !== 'object') return null

  if (!_has(val, propertyId)) {
    propertyId = decodeURI(propertyId)
  }
  return _get(val, propertyId)
}
