import fnsDateFormat from 'date-fns/format'
import fnsDateParse from 'date-fns/parse'

function formatNotionFormDate(rawValue, field) {
  let output = ''
  if (rawValue && rawValue.start) {
    const startDate = fnsDateParse(
      rawValue.start.date,
      'yyyy-MM-dd HH:mm:ss.SSSSSS',
      new Date(),
    )
    output += fnsDateFormat(startDate, (field.with_time === true) ? 'yyyy-MM-dd HH:mm' : 'yyyy-MM-dd')
  }
  if (rawValue && rawValue.end) {
    const endDate = fnsDateParse(
      rawValue.end.date,
      'yyyy-MM-dd HH:mm:ss.SSSSSS',
      new Date(),
    )
    output += ` - ${fnsDateFormat(endDate, (field.with_time === true) ? 'yyyy-MM-dd HH:mm' : 'yyyy-MM-dd')}`
  }
  return output
}

function formatNotionFormFile(rawValue) {
  if (rawValue === undefined || !rawValue)
    return null

  const returnValues = []
  rawValue.forEach((r) => {
    if (r.file && r.file.url)
      returnValues.push(r.file.url)
    else if (r.external && r.external.url)
      returnValues.push(r.external.url)
  })
  return returnValues
}

function formatNotionFormArrayBy(keyname, rawValue) {
  if (rawValue === undefined || !rawValue)
    return null

  const returnValues = []
  rawValue.forEach((r) => {
    returnValues.push(r[keyname])
  })
  return returnValues
}

function formatNotionFormTags(rawValue) {
  if (rawValue === null || rawValue === undefined)
    return ''

  return rawValue.name ?? ''
}

function formatNotionFormText(rawValue) {
  if (rawValue === undefined || !rawValue)
    return ''

  if (
    typeof rawValue === 'object'
    && rawValue !== null
    && rawValue.plainText !== undefined
  )
    return rawValue.plainText.toString() ?? ''
  else
    return rawValue.toString() ?? ''
}

export function notionFormFormatData(field, rawValue) {
  switch (field.type) {
    case 'date':
      return formatNotionFormDate(rawValue, field)
    case 'files':
      return formatNotionFormFile(rawValue)
    case 'multi_select':
      return formatNotionFormArrayBy('name', rawValue)
    case 'people':
    case 'relation':
      return formatNotionFormArrayBy('id', rawValue)
    case 'select':
    case 'status':
    case 'tags':
      return formatNotionFormTags(rawValue)
    case 'text':
    case 'title':
    case 'rich_text':
    case 'number':
      return formatNotionFormText(rawValue)
    case 'url':
    case 'phone':
    case 'email':
    case 'checkbox':
      return rawValue
    default:
      return ''
  }
}
