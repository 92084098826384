import { defineStore } from 'pinia'
import { useContentStore } from '~/composables/stores/useContentStore.js'

/**
 * Loads records from database
 */

export const useRecordsStore = defineStore('records', () => {
  const contentStore = useContentStore('page_id')

  const loadRecord = (request) => {
    contentStore.set([])
    contentStore.startLoading()
    return request.then((data) => {
      contentStore.save(data)
      contentStore.stopLoading()
    })
  }

  return {
    ...contentStore,
    loadRecord,
  }
})
